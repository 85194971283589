import { Link } from "react-router-dom";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { classNames } from "@/lib/styles";
import { NavigationItem } from "@/types/navigation";

type NavigationDropdownProps = {
  item: NavigationItem;
  onLinkClick?: () => void; // Used to close the sidebar
};
export default function NavigationDropdown({ item, onLinkClick }: NavigationDropdownProps) {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              item?.pattern?.exec(location.pathname)
                ? "bg-quincy-800 text-white"
                : "text-quincy-100 hover:bg-quincy-600",
              "group flex justify-between items-center px-2 py-2 text-sm font-medium rounded-md w-full max-w-xs",
            )}
          >
            <span className="flex flex-row leading-6">
              {item.icon && (
                <item.icon
                  className="mr-3 h-6 w-6 flex-shrink-0 text-quincy-300"
                  aria-hidden="true"
                />
              )}
              {item.name}
            </span>
            <ChevronDownIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } ml-3 h-5 w-5 flex-shrink-0 transition-transform text-quincy-300`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="pl-9 pr-2 text-sm text-gray-500">
            {item.children?.map((link) => (
              <Link
                key={link.name}
                to={link.href}
                onClick={onLinkClick} // Used to close the sidebar
                className={classNames(
                  link?.pattern?.exec(location.pathname)
                    ? "bg-quincy-800 text-white"
                    : "text-quincy-100 hover:bg-quincy-600",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                )}
              >
                {link.name}
              </Link>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
