export const PENDING_REDIRECT_KEY = "pending_redirect";

export function getPendingRedirect() {
  return sessionStorage.getItem(PENDING_REDIRECT_KEY);
}

export function setPendingRedirect(redirect: string) {
  sessionStorage.setItem(PENDING_REDIRECT_KEY, redirect);
}

export function clearPendingRedirect() {
  sessionStorage.removeItem(PENDING_REDIRECT_KEY);
}
