import { Link, useLocation } from "react-router-dom";

import NavigationDropdown from "./NavigationDropdown";
import Logo from "@/components/Logo";
import { classNames } from "@/lib/styles";
import { UserNavigation } from "@/types/navigation";

type NavigationPrimaryMediumProps = {
  items: UserNavigation;
};

export default function NavigationPrimaryLarge({ items }: NavigationPrimaryMediumProps) {
  const location = useLocation();

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-grow flex-col overflow-y-auto bg-quincy-800 pt-5">
        <div className="flex flex-shrink-0 items-center px-4">
          <Logo className="h-16 w-auto" alt="Jordplan logo" lineColor="#ffffff" />
        </div>
        <div className="mt-5 flex flex-1 flex-col">
          <nav className="flex-1 space-y-1 px-2 pb-4">
            <>
              {items.map((item) =>
                item.children ? (
                  <NavigationDropdown key={item.name} item={item} />
                ) : (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item?.pattern?.exec(location.pathname)
                        ? "bg-quincy-800 text-white"
                        : "text-quincy-100 hover:bg-quincy-600",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                    )}
                  >
                    {item.icon && (
                      <item.icon
                        className="mr-3 h-6 w-6 flex-shrink-0 text-quincy-300"
                        aria-hidden="true"
                      />
                    )}
                    {item.name}
                  </Link>
                ),
              )}
            </>
          </nav>
        </div>
      </div>
    </div>
  );
}
