import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import {
  clearPendingRedirect,
  getPendingRedirect,
  setPendingRedirect,
} from "@/lib/pending_redirects";
import User from "@/models/User";
import useAppZustand from "@/stores/appZustand";

function PrivateRoutes() {
  console.log("🌱 PrivateRoutes");
  const user = useAppZustand(({ user }) => user);

  if (User.isAnonymous(user)) {
    console.log("🌱 PrivateRoutes - Anonymous");
    if (document.location.pathname !== "/") {
      setPendingRedirect(document.location.pathname);
    }
    return <Navigate to="/auth/login" />;
  }

  const pendingRedirect = getPendingRedirect();
  console.log("🌱 PrivateRoutes - pendingRedirect", pendingRedirect);
  if (pendingRedirect && document.location.href.endsWith(pendingRedirect)) {
    clearPendingRedirect();
  }
  if (pendingRedirect) {
    console.log("🌱 PrivateRoutes - Following redirect");
    // return <Navigate to={pendingRedirect} />;
    window.location.replace(pendingRedirect);
    return;
  }

  console.log("🌱 PrivateRoutes - Outlet 💪");
  return <Outlet />;
}

export default PrivateRoutes;
