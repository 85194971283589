import i18next from "i18next";
import { TLanguage } from "@/types/language.ts";

export async function conditionallyChangeLanguage(language: TLanguage) {
  if (i18next.language === language) {
    return;
  }
  try {
    await i18next.changeLanguage(language);
  } catch (err) {
    console.error("Error changing language", err);
  }
  return language;
}
