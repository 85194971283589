import { TailwindMedia } from "@/types/tailwindMedia.ts";

type ClassName = string | boolean;

export function classNames(...classes: ClassName[]) {
  return classes.filter(Boolean).join(" ");
}

export function tailwindMedia(width: number): TailwindMedia {
  if (width >= 1536) return "2xl";
  if (width >= 1280) return "xl";
  if (width >= 1024) return "lg";
  if (width >= 768) return "md";
  if (width >= 640) return "sm";
  return "";
}
