import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import { clearPendingRedirect } from "@/lib/pending_redirects";

export const ErrorBoundary = () => {
  const error: any = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      clearPendingRedirect();
    }
  }

  console.log(error);

  return (
    <div>
      <h1>{error.status}</h1>
      <h2>{error.data?.sorry}</h2>
      <p>Send en e-post til kontakt@jordplan.no hvis du føler at dette er en feil.</p>
      <div>
        <a href="/">Gå til forsiden</a>
        <span> eller </span>
        <a
          href="#"
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
          }}
        >
          nullstill applikasjonen
        </a>
        <div>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};
