import {
  IAccount,
  IAddress,
  IName,
  IOrganization,
  IPhoneNumber,
  IUser,
  TExtent,
  TLocation,
} from "@/types";
import { TLanguage } from "@/types/language.ts";

const anonymousId = "0";

export default class User implements IUser {
  // TODO Use get and set to fix assign
  id = "";

  phoneNumber: IPhoneNumber = {
    countryCode: "",
    number: "",
  };

  email = "";

  name: IName = { familyName: "", givenName: "" };

  address: IAddress = {
    postalCode: "",
    postalPlace: "",
    address1: "",
    address2: "",
    country: "",
    countryCode: "",
  };

  language: TLanguage;

  location: TLocation;

  extent?: TExtent;

  organizations: IOrganization[] = [];
  accounts: IAccount[] = [];

  constructor({
    id,
    name,
    address,
    email,
    phoneNumber,
    language,
    location,
    organizations,
    accounts,
  }: IUser) {
    this.id = id as string;
    this.name = name;
    this.address = address;
    this.email = email || "";
    this.phoneNumber = phoneNumber;
    this.location = location || [];
    this.language = language || "sv";
    this.organizations = organizations ?? [];
    this.accounts = accounts ?? [];
  }

  // NB Loses class methods when it is loaded from storage

  static isAnonymous(user: User) {
    if (!user) {
      console.warn("User.isAnonymous testing", user);
    }
    return !user || user.id === anonymousId;
  }

  static getAnonymous() {
    return new User({ id: anonymousId } as IUser);
  }

  static getOrganization(user: User, id: string) {
    return user.organizations.find((org) => org.id === id);
  }
}
