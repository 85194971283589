import React, { useMemo } from "react";

import { Transition } from "@headlessui/react";

import GreenAlert from "./GreenAlert";
import RedAlert from "./RedAlert";
import useAppZustand, { AppActions, AppState } from "@/stores/appZustand";
import { IAlert } from "@/types";

type AlertContainerProps = {
  extraClassNames?: string;
};

function Alert({ extraClassNames }: AlertContainerProps) {
  const { alerts, removeAlert }: Pick<AppState & AppActions, "alerts" | "removeAlert"> =
    useAppZustand(({ alerts, removeAlert }) => ({
      alerts,
      removeAlert,
    }));

  const memoizedAlerts = useMemo(
    () =>
      Object.values(alerts).map(({ id, message, type }) => ({
        id,
        message,
        type,
        action: () => removeAlert(id),
      })),
    [alerts],
  );

  return (
    <div className={`flex flex-col py-6 ${extraClassNames}`}>
      {memoizedAlerts.map((alert: IAlert) => {
        if (alert.type === "error") {
          return RedAlert({
            ...alert,
            action: () => removeAlert(alert.id),
          });
        }
        return GreenAlert({
          ...alert,
          action: () => removeAlert(alert.id),
        });
      })}
    </div>
  );
}

export default function AlertContainer({ extraClassNames }: AlertContainerProps) {
  const { alerts }: Partial<AppState> = useAppZustand(({ alerts }) => ({
    alerts,
  }));
  return (
    <Transition
      // ref={forwardedRef}
      appear={true}
      unmount={true}
      show={Object.keys(alerts).length > 0}
      enter="transition-all duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {/* Need the following div to avoid errors related to reference passing */}
      <div>
        <Alert extraClassNames={extraClassNames} />
      </div>
    </Transition>
  );
}
