import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

import en from "./en/common.json";
import no from "./no/common.json";
import sv from "@/i18n/sv/common.json";

const resources = {
  en: {
    translation: en,
  },
  no: {
    translation: no,
  },
  sv: {
    translation: sv,
  },
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "sv",
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      backends: [LocalStorageBackend],
      backendOptions: [],
      cacheHitMode: "refreshAndUpdateStore",
    },
  });

export default i18n;
