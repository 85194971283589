import { baseUrl } from "./wally";
import User from "@/models/User";

export const ID_TOKEN_KEY = "idToken";

export const usingIdCookie = (): boolean => document.location.hostname === "jordplan.no";

export const removeIdToken = async (): Promise<void> => {
  if (usingIdCookie()) {
    console.log("Clearing cookie based token");
    const res: Response = await fetch(`${baseUrl}/authenticate/token`, {
      method: "DELETE",
    });
    if (res.status !== 204) {
      console.error("Unexpected return value while clearing cookied based token:");
      console.error(await res.text());
    }
  } else {
    console.log("Clearing localStorage based token");
    localStorage.removeItem(ID_TOKEN_KEY);
  }
};

export const setIdToken = (token: string) => {
  localStorage.setItem(ID_TOKEN_KEY, token);
};

export const getIdToken = () => localStorage.getItem(ID_TOKEN_KEY);

export const getIdTokenHeader = (token?: string): HeadersInit | undefined => {
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  if (usingIdCookie()) {
    return {}; // Nothing if we're using an httpOnly cookie
  }
  const idToken = getIdToken();
  if (idToken) {
    return { Authorization: `Bearer ${idToken}` };
  }
  return {};
};

export const fetchMe = async (token?: string) => {
  if (!token && !(usingIdCookie() || getIdToken())) {
    return null;
  }

  const meRes = await fetch(`${baseUrl}/v2/user/me`, {
    headers: getIdTokenHeader(token),
  });
  if (meRes.status === 200) {
    const body = await meRes.json();
    const me = new User(body);
    return me;
  }
  return null;
};

export const loginUser = async (values: { username: string; password: string }) => {
  return await fetch(`${baseUrl}/v2/authenticate/login/email`, {
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...values,
      cookie: usingIdCookie(),
    }),
    method: "POST",
  });
};
