import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";

import { fetchMe } from "@/lib/auth.ts";
import { classNames } from "@/lib/styles";
import useAppZustand from "@/stores/appZustand";
import { UserNavigation } from "@/types/navigation";

export default function UserDropDownMenu() {
  const { t } = useTranslation();
  const { user, login } = useAppZustand(({ user, login }) => ({
    user,
    login,
  }));

  const userNavigation: UserNavigation = [
    {
      name: t("Reload"),
      href: "#",
      onClick: async () => {
        const me = await fetchMe();
        if (me !== null) {
          login(me);
        }
      },
    },
    {
      name: t("Sign out"),
      href: "/logout",
    },
  ];

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-quincy-500 focus:ring-offset-2">
          <span className="sr-only">Åpne brukermeny</span>
          <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
            <span className="font-medium leading-none text-white">{`${user.name.givenName[0]}${user.name.familyName[0]}`}</span>
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <Link
                  to={item.href}
                  onClick={item.onClick}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700",
                  )}
                >
                  {item.name}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
