import { ICountry } from "@/types/country";

type Countries = {
  [countryCode: string]: Omit<ICountry, "countryCode">;
};
export const countries: Countries = {
  NO: {
    english: "Norway",
    name: "Norge",
    phonePrefix: 47,
    timezone: "Europe/Oslo",
    currencyCode: "NOK",
  },
  SE: {
    english: "Sweden",
    name: "Sverige",
    phonePrefix: 46,
    timezone: "Europe/Stockholm",
    currencyCode: "SEK",
  },
  DK: {
    english: "Denmark",
    name: "Danmark",
    phonePrefix: 45,
    timezone: "Europe/Copenhagen",
    currencyCode: "DKK",
  },
  FI: {
    english: "Finland",
    name: "Suomi",
    phonePrefix: 358,
    timezone: "Europe/Helsinki",
    currencyCode: "EUR",
  },
  LT: {
    english: "Lithuania",
    name: "Lietuva",
    phonePrefix: 370,
    timezone: "Europe/Vilnius",
    currencyCode: "EUR",
  },
  EE: {
    english: "Estonia",
    name: "Eesti",
    phonePrefix: 372,
    timezone: "Europe/Tallinn",
    currencyCode: "EUR",
  },
  LV: {
    english: "Latvia",
    name: "Latvija",
    phonePrefix: 371,
    timezone: "Europe/Riga",
    currencyCode: "EUR",
  },
  PL: {
    english: "Poland",
    name: "Polska",
    phonePrefix: 48,
    timezone: "Europe/Warsaw",
    currencyCode: "EUR", // Not what they use, but we don't support PLN
  },
  RO: {
    english: "Romania",
    name: "România",
    phonePrefix: 40,
    timezone: "Europe/Bucharest",
    currencyCode: "EUR",
  },
  BG: {
    english: "Bulgaria",
    name: "България",
    phonePrefix: 359,
    timezone: "Europe/Sofia",
    currencyCode: "EUR", // Not what they use, but we don't support BGN
  },
  DE: {
    english: "Germany",
    name: "Deutschland",
    phonePrefix: 49,
    timezone: "Europe/Berlin",
    currencyCode: "EUR",
  },
  NL: {
    english: "Netherlands",
    name: "Nederland",
    phonePrefix: 31,
    timezone: "Europe/Amsterdam",
    currencyCode: "EUR",
  },
  UK: {
    english: "United Kingdom",
    name: "United Kingdom",
    phonePrefix: 44,
    timezone: "Europe/London",
    currencyCode: "GBP",
  },
  IE: {
    english: "Ireland",
    name: "Ireland",
    phonePrefix: 353,
    timezone: "Europe/Dublin",
    currencyCode: "EUR",
  },
  ES: {
    english: "Spain",
    name: "España",
    phonePrefix: 34,
    timezone: "Europe/Madrid",
    currencyCode: "EUR",
  },
  FR: {
    english: "France",
    name: "France",
    phonePrefix: 33,
    timezone: "Europe/Paris",
    currencyCode: "EUR",
  },
  IT: {
    english: "Italy",
    name: "Italia",
    phonePrefix: 39,
    timezone: "Europe/Rome",
    currencyCode: "EUR",
  },
  PT: {
    english: "Portugal",
    name: "Portugal",
    phonePrefix: 351,
    timezone: "Europe/Lisbon",
    currencyCode: "EUR",
  },
  TR: {
    english: "Turkey",
    name: "Türkiye",
    phonePrefix: 90,
    timezone: "Europe/Istanbul",
    currencyCode: "EUR",
  },
  GR: {
    english: "Greece",
    name: "Ελλάδα",
    phonePrefix: 30,
    timezone: "Europe/Athens",
    currencyCode: "EUR",
  },
  AT: {
    english: "Austria",
    name: "Österreich",
    phonePrefix: 43,
    timezone: "Europe/Vienna",
    currencyCode: "EUR",
  },
  BR: {
    english: "Brazil",
    name: "Brasil",
    phonePrefix: 55,
    timezone: "America/Sao_Paulo",
    currencyCode: "USD", // Not what they use, but we don't support BRL
  },
  US: {
    english: "United States",
    name: "United States",
    phonePrefix: 1,
    timezone: "America/Chicago",
    currencyCode: "USD",
  },
  UA: {
    english: "Ukraine",
    name: "Україна",
    phonePrefix: 380,
    timezone: "Europe/Kiev",
    currencyCode: "EUR", // Not what they use
  },
  NZ: {
    english: "New Zealand",
    name: "New Zealand",
    phonePrefix: 64,
    timezone: "Pacific/Auckland",
    currencyCode: "USD", // Not what they use, but we don't support NZD
  },
  AU: {
    english: "Australia",
    name: "Australia",
    phonePrefix: 61,
    timezone: "Australia/Sydney",
    currencyCode: "USD", // Not what they use, but we don't support AUD
  },
  VN: {
    english: "Vietnam",
    name: "Việt Nam",
    phonePrefix: 84,
    timezone: "Asia/Ho_Chi_Minh",
    currencyCode: "USD",
  },
  TH: {
    english: "Thailand",
    name: "ประเทศไทย",
    phonePrefix: 66,
    timezone: "Asia/Bangkok",
    currencyCode: "USD",
  },
  MY: {
    english: "Malaysia",
    name: "Malaysia",
    phonePrefix: 60,
    timezone: "Asia/Kuala_Lumpur",
    currencyCode: "USD",
  },
};

export const getPhonePrefixes = () => {
  const phonePrefixes = Object.entries(countries).map(([key, value]) => ({
    countryCode: key,
    phonePrefix: value.phonePrefix as number,
  }));
  phonePrefixes.sort(({ phonePrefix: a }, { phonePrefix: b }) => a - b);
  return phonePrefixes;
};

export const getCountryNames = (): ICountry[] => {
  const countryNames = Object.entries(countries).map(([key, value]) => ({
    countryCode: key,
    english: value.english,
    name: value.name,
  }));
  countryNames.sort(({ english: a }, { english: b }) => a.localeCompare(b));
  return countryNames;
};
