import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { clearPendingRedirect, getPendingRedirect } from "@/lib/pending_redirects.ts";
import User from "@/models/User";
import useAppZustand from "@/stores/appZustand";

function AnonymousRoutes() {
  const user = useAppZustand(({ user }) => user);

  if (User.isAnonymous(user)) {
    return <Outlet />;
  }

  const pendingRedirect = getPendingRedirect();
  // Clearing the redirect doesnt work well because this route can be rendered twice
  // before the redirect happens, in which case we end up at "/"
  // if (pendingRedirect) clearPendingRedirect();
  window.location.replace(pendingRedirect || "/");
  return null;
}

export default AnonymousRoutes;
