import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";

import FlagUsEn from "../icons/FlagUsEn.tsx";
import FlagNo from "../icons/FlagNo.tsx";
import FlagSe from "../icons/FlagSe.tsx";

interface ILanguage {
  title: string;
  code: string;
  icon: ReactNode;
}
const languages: ILanguage[] = [
  { title: "English", code: "en", icon: <FlagUsEn /> },
  { title: "Norsk", code: "no", icon: <FlagNo /> },
  { title: "Svenska", code: "sv", icon: <FlagSe /> },
];

type LangSelectProps = {
  defaultLanguage?: string;
};

export default function LangSelect({ defaultLanguage }: LangSelectProps = {}) {
  const { i18n } = useTranslation();
  const resolvedLanguage = languages.find((lang) => lang.code === i18n.resolvedLanguage);
  const [selected, setSelected] = useState(resolvedLanguage || languages[0]);

  const handleLanguageChange = (lang: ILanguage) => {
    setSelected(lang);
    i18n.changeLanguage(lang.code);
  };

  return (
    <Listbox value={selected} onChange={handleLanguageChange}>
      <div className="relative mt-1 mx-8">
        <ListboxButton className="relative w-full">
          <div className="w-5">{selected.icon}</div>
        </ListboxButton>
        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ListboxOptions className="absolute -left-24 z-50 mt-1 max-h-60 w-full min-w-[150px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {languages.map((lang, idx) => (
              <ListboxOption
                key={idx}
                className={`relative cursor-default select-none py-2 pl-10 pr-4 ${
                  selected.code === lang.code ? "bg-amber-100 text-amber-900" : "text-gray-900"
                }`}
                value={lang}
              >
                {({ selected }) => (
                  <>
                    <span className={`block truncate ${selected ? "font-bold" : "font-normal"}`}>
                      {lang.title}
                    </span>

                    <span className="absolute inset-y-0 left-0 w-7 flex items-center pl-3 text-amber-600">
                      {lang.icon}
                    </span>
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
}
