import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Logo from "@/components/Logo";
import { classNames } from "@/lib/styles";
import { NavigationItem, UserNavigation } from "@/types/navigation";
import NavigationDropdown from "@/layouts/components/NavigationDropdown.tsx";
import { set } from "ol/transform";

type NavigationPrimarySmall = {
  setSidebarOpen: (open: boolean) => void;
  items: UserNavigation;
};
export default function NavigationPrimarySmall({ setSidebarOpen, items }: NavigationPrimarySmall) {
  const location = useLocation();

  return (
    <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-quincy-800 pt-5 pb-4">
      <Transition.Child
        as={Fragment}
        enter="ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute top-0 right-0 -mr-12 pt-2">
          <button
            type="button"
            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            onClick={() => setSidebarOpen(false)}
          >
            <span className="sr-only">Close sidebar</span>
            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </Transition.Child>
      <div className="flex flex-shrink-0 items-center px-4">
        <Logo className="h-8 pl-4 w-auto" alt="Jordplan logo" lineColor="#ffffff" />
      </div>
      <div className="mt-5 h-0 flex-1 overflow-y-auto">
        <nav className="space-y-1 px-2">
          {items.map((item: NavigationItem) =>
            item.children ? (
              <NavigationDropdown
                key={item.name}
                item={item}
                onLinkClick={() => setSidebarOpen(false)}
              />
            ) : (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item?.pattern?.exec(location.pathname)
                    ? "bg-quincy-800 text-white"
                    : "text-quincy-100 hover:bg-quincy-600",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                )}
              >
                {item.icon && (
                  <item.icon
                    className="mr-3 h-6 w-6 flex-shrink-0 text-quincy-300"
                    aria-hidden="true"
                  />
                )}
                {item.name}
              </Link>
            ),
          )}
        </nav>
      </div>
    </Dialog.Panel>
  );
}
